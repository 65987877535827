import sortRowName from '../Sorters/sortRowName'
import sortHubs from '../Sorters/sortHubs'
import sortOrgs from '../Sorters/sortOrgs'
import sortEmail from '../Sorters/sortEmail'
import sortFirstname from '../Sorters/sortFirstname'

const useSorter = (type) => {
  const Sorters = {}

  Sorters.rowName = sortRowName
  Sorters.hubs = sortHubs
  Sorters.organization = sortOrgs
  Sorters.email = sortEmail
  Sorters.firstName = sortFirstname

  return Sorters[type]
}

export default useSorter
