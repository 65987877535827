import React, { useContext, useLayoutEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  useTable, useSortBy, useRowSelect, useGroupBy, useExpanded, usePagination
} from 'react-table'

import Icon from '@mdi/react'
import { mdiArrowUp, mdiArrowDown } from '@mdi/js'

import useDimensions from '../../Utils/Hooks/useDimensions'

import TableContext from '../../State/Table/Context'
import HeaderCheckbox from './Components/HeaderCheckbox'
import RowCheckbox from './Components/RowCheckbox'
import TableSkeletonLoader from './Components/TableSkeletonLoader'
import EmptyTableCTA from './Components/EmptyTableCTA'
import {
  TableContainer, TableHeader, TableHeaderCell, TableBody, TableBodyRow, TableBodyCell, EmptyListLink
} from './Styles'
import Pagination from '../Pagination'

const Table = ({
  isLoading, initialSortBy, columns, data, isExpanded, isFiltered, emptyList, status, tableCanBeGrouped
}) => {
  const { actions: { setSelectedCheckboxes } } = useContext(TableContext)
  const [targetRef, { y: controlsHeight, scrollbarWidth }] = useDimensions(isLoading)
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    selectedFlatRows,
    rows,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleGroupBy,
    state: {
      selectedRowIds,
      pageIndex,
      pageSize
    }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
        sortBy: [{ id: initialSortBy, desc: false }]
      }
    },
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push(tableColumns => (!tableCanBeGrouped ? [...tableColumns] : [
        {
          id: 'selection',
          Header: HeaderCheckbox,
          Cell: RowCheckbox,
          Aggregated: RowCheckbox,
          width: 56
        },
        ...tableColumns
      ]))
    }
  )
  const firstRow = rows[0]

  useMemo(() => {
    setSelectedCheckboxes(selectedFlatRows.reduce((accumulator, current) => {
      if (current.original) accumulator.push(current.original)
      return accumulator
    }, []))
  }, [selectedRowIds]) // eslint-disable-line

  useLayoutEffect(() => {
    toggleGroupBy('zoneId', isExpanded)
  }, [isExpanded, JSON.stringify(data)]) // eslint-disable-line

  useLayoutEffect(() => {
    if (firstRow && firstRow.canExpand) {
      rows.forEach(row => row.toggleRowExpanded(true))
    }
  }, [firstRow]) // eslint-disable-line

  const renderSortIcon = (column) => {
    if (column.isSorted) {
      return column.isSortedDesc ? <Icon path={mdiArrowDown} size={0.65} /> : <Icon path={mdiArrowUp} size={0.65} />
    }
    return ''
  }

  const renderCell = (row, cell) => {
    if (cell.isGrouped) {
      return cell.render('Cell')
    }

    if (cell.isAggregated) {
      return cell.render('Aggregated')
    }

    return cell.render('Cell')
  }

  if (isLoading) {
    return <TableSkeletonLoader />
  }

  const renderHeaderTestId = column => typeof column.Header === 'function' ? 'checkbox' : column.Header.toString().toLowerCase() //eslint-disable-line

  const renderEmptyTableMessage = () => {
    const isInActiveStatus = status === 'inactive' || status === 'archived'

    if (!isLoading && !data.length && isInActiveStatus) {
      return <EmptyTableCTA>{emptyList.emptyArchive}</EmptyTableCTA>
    }

    if (!isLoading && !data.length && !isFiltered) {
      return (
        <EmptyTableCTA>
          {emptyList.unfilteredMessage}
          &nbsp;
          {emptyList.ctaFunc && <EmptyListLink onClick={emptyList.ctaFunc}>{emptyList.ctaLabel}</EmptyListLink>}
        </EmptyTableCTA>
      )
    }

    if (!isLoading && !data.length && isFiltered) {
      return <EmptyTableCTA>{emptyList.filteredMessage}</EmptyTableCTA>
    }

    return <></>
  }

  return (
    <>
      <TableContainer {...getTableProps()} data-testid="table-container">
        <TableHeader scrollbarWidth={scrollbarWidth} data-testid="table-header">
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <TableHeaderCell
                  isSorted={column.isSorted}
                  cellWidth={column.width}
                  flex={column.flex}
                  align={column.align}
                  {...column.getHeaderProps()}
                >
                  <div {...column.getSortByToggleProps()} data-testid={`table-header-${renderHeaderTestId(column)}`}>
                    {renderSortIcon(column)}
                    {column.render('Header')}
                  </div>
                </TableHeaderCell>
              ))}
            </tr>
          ))}
        </TableHeader>
        <TableBody {...getTableBodyProps()} ref={targetRef} controlsHeight={!data.length ? '100vh' : controlsHeight} data-testid="table-body">
          {page.map(
            (row, rowIndex) => prepareRow(row) || (
              <TableBodyRow {...row.getRowProps()} isSelected={row.isSelected} isLastRow={rows[rowIndex + 1] && rows[rowIndex + 1].canExpand}>
                {row.cells.map((cell, index) => {
                  const { width, flex } = headerGroups[0].headers[index]
                  return (
                    <TableBodyCell
                      {...cell.getCellProps()}
                      cellWidth={width}
                      flex={flex}
                    >
                      {renderCell(row, cell)}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          )}
        </TableBody>
      </TableContainer>
      {data.length > 0 && (
        <Pagination
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageOptions={pageOptions}
          pageCount={pageCount}
          gotoPage={gotoPage}
          nextPage={nextPage}
          previousPage={previousPage}
          setPageSize={setPageSize}
          pageSize={pageSize}
          pageIndex={pageIndex}
        />
      )}
      {renderEmptyTableMessage()}
    </>
  )
}

Table.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  isFiltered: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isExpanded: PropTypes.bool,
  emptyList: PropTypes.shape({
    filteredMessage: PropTypes.string.isRequired,
    unfilteredMessage: PropTypes.string.isRequired,
    emptyArchive: PropTypes.string,
    ctaLabel: PropTypes.string,
    ctaFunc: PropTypes.func
  }).isRequired,
  status: PropTypes.string,
  setSelectedCheckboxes: PropTypes.func,
  idField: PropTypes.string,
  tableCanBeGrouped: PropTypes.bool,
  initialSortBy: PropTypes.string
}

Table.defaultProps = {
  isExpanded: false,
  status: undefined,
  setSelectedCheckboxes: () => {},
  idField: undefined,
  tableCanBeGrouped: false,
  initialSortBy: undefined
}

export default Table
