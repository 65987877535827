import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { toDate, differenceInMinutes } from 'date-fns'
import Config from '../../Config'

const userClient = {
  login(username, password) {
    return firebase.auth().signInWithEmailAndPassword(username, password)
  },
  logout() {
    return firebase.auth().signOut()
  },
  onAuthStateChanged(callback) {
    return firebase.auth().onAuthStateChanged(callback)
  },
  reAuthenticate(password) {
    const { email } = firebase.auth().currentUser
    const credential = firebase.auth.EmailAuthProvider.credential(email, password)
    return firebase.auth().currentUser.reauthenticateWithCredential(credential)
  },
  async getUserData(userId) {
    const data = {}
    const unsubscribe = firebase.firestore().collection('users').doc(userId)
      .onSnapshot(doc => Object.assign(data, doc.data()))
    return { data, unsubscribe }
  },
  getIdToken() {
    return firebase.auth().currentUser.getIdToken()
  },
  async getUserPermissions(userId) {
    const permissionsQuery = firebase.firestore().collection(`/users/${userId}/permissions`)
      .get()
      .then((querySnapshot) => {
        const data = querySnapshot.docs.reduce((accumulator, current) => {
          accumulator.push({ id: current.id, ...current.data() })
          return accumulator
        }, [])
        return data
      })
    const organizationsQuery = firebase.firestore().collection('organizations')
      .get()
      .then(({ docs }) => {
        const data = docs.reduce((accumulator, current) => {
          accumulator.push({ id: current.id, ...current.data() })
          return accumulator
        }, [])
        return data
      })
      .catch(() => null)

    return Promise.all([permissionsQuery, organizationsQuery])
      .then(([permissionsRes, organizationsRes]) => {
        if (organizationsRes) {
          const orgRes = organizationsRes.filter(org => org.id !== permissionsRes[0].id)
          return [...permissionsRes, ...orgRes]
        }
        return permissionsRes
      })
  },
  async getInviteData(inviteId) {
    const url = `${Config.cloud.api.host}/invite/${inviteId}`
    return fetch(url, {
      method: 'GET',
      cache: 'no-cache'
    })
  },
  async deleteInvite(inviteId) {
    await firebase
      .firestore()
      .collection('invitations')
      .doc(inviteId)
      .delete()
  },
  async deleteAllOrgInvites(organization) {
    const batch = firebase.firestore().batch()
    const allInvitations = await firebase.firestore().collection('invitations').get().then(({ docs }) => docs)
    const invitesToDelete = allInvitations.filter(invitation => invitation.data().organizationId === organization.id)
    invitesToDelete.forEach((invite) => {
      const inviteRef = firebase.firestore().doc(`/invitations/${invite.id}`)
      batch.delete(inviteRef)
    })

    return batch.commit()
  },
  async createUser(values) {
    const {
      email, password
    } = values
    const userCredential = await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)

    const { uid } = userCredential.user
    return uid
  },
  async processInvite(inviteId, values) {
    const {
      firstName, lastName, language, email
    } = values
    const url = `${Config.cloud.api.host}/invite/${inviteId}/accept`
    const token = await this.getIdToken()
    return fetch(url, {
      method: 'PUT',
      cache: 'no-cache',
      credentials: 'omit',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        firstName, lastName, language, email
      })
    })
  },
  async isAuthSessionFresh() {
    const TIMEOUT_PERIOD_MINS = 10
    const query = await firebase.auth().currentUser.getIdTokenResult()
    const { auth_time: authedAtTimestamp } = query.claims
    const authedAt = toDate(authedAtTimestamp * 1000)
    const currentTime = new Date()
    const difference = differenceInMinutes(authedAt, currentTime)
    return Math.abs(difference) < TIMEOUT_PERIOD_MINS
  },
  async sendSignInLinkToEmail(email, language) {
    const { host } = Config.cloud.api
    const url = `${host}/users/forgot-password/${email}?language=${language}`
    const res = await fetch(url, {
      method: 'GET',
      cache: 'no-cache'
    })
    return res
  },
  isSignInLink(link) {
    return firebase.auth().isSignInWithEmailLink(link)
  },
  signInWithEmailLink(email, emailLink) {
    return firebase.auth().signInWithEmailLink(email, emailLink)
  },
  async changeEmail(newEmail) {
    const { host } = Config.cloud.api
    const { uid: userId } = firebase.auth().currentUser

    const token = await firebase.auth().currentUser.getIdToken()
    const url = `${host}/users/${userId}/manage/email`
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      cache: 'no-cache',
      credentials: 'omit',
      body: JSON.stringify({ email: newEmail })
    })
    return res
  },
  async changePassword(newPassword) {
    const { uid: userId } = firebase.auth().currentUser
    return firebase.auth().currentUser.updatePassword(newPassword)
      .then(firebase.firestore().collection('users').doc(userId).set({ passwordUpdatedAt: new Date() }, { merge: true }))
  },
  updateProfile(formData) {
    const { uid: userId } = firebase.auth().currentUser
    return firebase.firestore().collection('users').doc(userId).set(formData, { merge: true })
  },
  async resendInviteEmail(inviteId) {
    const { host } = Config.cloud.api
    const token = await firebase.auth().currentUser.getIdToken()

    const url = `${host}/invite/${inviteId}/resend`
    const res = await fetch(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`
      },
      cache: 'no-cache'
    })
    return res
  },
  async updateStatus(organizationId, users) {
    const batch = firebase.firestore().batch()
    const updateUser = (userObj) => {
      const { userId, status } = userObj
      const userRef = firebase.firestore().doc(`/users/${userId}/permissions/${organizationId}`)
      batch.update(userRef, { status: status === 'active' ? 'inactive' : 'active' })
    }
    users.forEach(updateUser)
    return batch.commit()
  },
  async updatePermissionOrganizationStatus(organization) {
    const db = firebase.firestore()
    const batch = db.batch()
    const users = await db.collection('users').get()
      .then(({ docs }) => docs)
    const updatePromises = users.map(async (user) => {
      const userPermissionDocs = await user.ref.collection('permissions').get()
        .then(({ docs }) => docs)
      const selectedUserPermissions = userPermissionDocs.filter((permissionDoc) => {
        const { name } = permissionDoc.data()
        return organization.name === name
      })
      if (selectedUserPermissions && selectedUserPermissions.length === 1) {
        const userRef = firebase.firestore().doc(`/organizations/${organization.id}/permissions/${user.id}`)
        if (user.data().isPrimary && organization.status === 'archived') {
          batch.update(userRef, { status: 'active' })
        } else {
          batch.update(userRef, { status: 'inactive' })
        }
      }
      return selectedUserPermissions
    })

    return Promise.all(updatePromises)
      .then((res) => {
        const updateUsers = res.filter(e => e !== undefined)
        updateUsers.forEach((selectedUserPermissions) => {
          selectedUserPermissions.forEach((selectedUserPermission) => {
            batch.update(selectedUserPermission.ref, { status: selectedUserPermission.data().status === 'active' ? 'archived' : 'active' })
          })
        })
        return batch.commit()
      })
  },
  updateAdmin(userId, formData) {
    const docRef = firebase.firestore().collection('users')
    return docRef.doc(userId).set({ admin: formData.admin }, { merge: true })
  },
  getUserAdmin(userId) {
    const docRef = firebase.firestore().collection('users').doc(userId)
    return docRef.get().then(doc => doc.data().admin)
  },
  getDeltaControlsOrg(userId) {
    const dcOrgId = `${Config.firebase.dcOrgId}`
    const docRef = firebase.firestore().collection(`users/${userId}/permissions`).doc(dcOrgId)
    return docRef.get().then(doc => doc.exists)
  },
  async deleteSingleOrgUsersFromOrg(organization) {
    const db = firebase.firestore()
    const batch = db.batch()
    const users = await db.collection('users').get()
      .then(({ docs }) => docs)
    const updatePromises = users.map(async (user) => {
      const userPermissionDocs = await user.ref.collection('permissions').get()
        .then(({ docs }) => docs)
      if (userPermissionDocs.length === 1) {
        userPermissionDocs.forEach((permissionDoc) => {
          const { name } = permissionDoc.data()
          if (organization.name === name) {
            this.deleteUser(user.id)
            batch.delete(user.ref)
          }
        })
      }
    })

    return Promise.all(updatePromises)
      .then(() => batch.commit())
  },
  async deleteUser(uid) {
    const url = `${Config.cloud.api.host}/users/${uid}`
    const token = await this.getIdToken()
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      cache: 'no-cache',
      credentials: 'omit'
    })
  }
}

export default userClient
