import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import { TableCell } from '../../../Table'

const JobCount = ({ row }) => {
  const { jobsites, role } = row.original
  const translate = useTranslate('usersPage')
  const jobsitesLength = jobsites ? jobsites.length : '0'

  return (
    <TableCell align="right" data-testid="users-jobsites-count">
      {role === 'admin' ? translate('adminJobsColumn') : jobsitesLength}
    </TableCell>
  )
}

JobCount.propTypes = {
  row: PropTypes.object.isRequired
}

export default JobCount
