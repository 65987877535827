import React, { useMemo, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../Utils/Hooks/useTranslate'
import useSearch from '../../../Utils/Hooks/useSearch'
import useSorter from '../../../Utils/Hooks/useSorter'

import FilterWrapper from '../../FilterWrapper'
import Search from '../../Search'
import Table from '../../Table'
import Select from '../../Select'
import AttributeName from './Components/AttributeName'
import EnabledStatus from './Components/EnabledStatus'
import Value from './Components/Value'
import AttributeType from './Components/AttributeType'
import Description from './Components/Description'
import Details from './Components/Details'

const searchFields = ['name']

const DeviceAttributesTable = ({ device, isLoading }) => {
  const [selectedType, setSelectedType] = useState('all')
  const [searchQuery, setSearchQuery] = useState('')
  const [filteredResults, setFilteredResults] = useState([])
  const isFiltered = !!searchQuery || selectedType !== 'all'
  const translate = useTranslate('templateAttributesPage')
  const translateAttributes = useTranslate('attributesPage')
  const sortAttributeName = useSorter('rowName')
  const types = [
    'temperature',
    'humidity',
    'occupancy',
    'environmental',
    'lightRing',
    'sound',
    'bluetooth',
    'irBlaster',
    'inputOutput'
  ]

  const allOption = [
    {
      value: 'all',
      label: translate('allTypeOption')
    }
  ]
  const typeOptions = allOption.concat(...types.map(type => ({ value: type, label: translate(`${type}TypeOption`) })))
  useEffect(() => {
    if (device && device !== null) {
      const reducer = (acc, current, currentIndex) => {
        const combinedAttribute = { attributeId: Object.keys(device.config.sensors)[currentIndex], ...current }
        return acc.concat(combinedAttribute)
      }
      const sorter = (a, b) => {
        if (a.type.toLowerCase() > b.type.toLowerCase()) {
          return 1
        }

        if (a.type.toLowerCase() < b.type.toLowerCase()) {
          return -1
        }

        return 0
      }
      const buildCombinedAttributeData = () => (device.config ? Object.values(device.config.sensors).reduce(reducer, []) : [])
      const combinedAttributeData = device ? buildCombinedAttributeData() : []
      setFilteredResults(combinedAttributeData.filter(type => selectedType === 'all' || type.type === selectedType).sort(sorter))
    }
  }, [device, selectedType])

  const columns = useMemo(
    () => [
      {
        id: 'name',
        isFirstRow: true,
        Header: translate('tableAttributeNameColumn'),
        accessor: 'name',
        Cell: ({ row }) => (<AttributeName row={row} device={device} />), /* eslint-disable-line */
        width: 572,
        sortType: sortAttributeName,
        flex: true
      },
      {
        Header: translate('tableValueColumn'),
        Cell: Value,
        accessor: 'displayValue', // displayValue comes is injected into the attribute object in the `useSearch` hook
        align: 'right',
        width: 144
      },
      {
        Header: translate('tableStatusColumn'),
        Cell: EnabledStatus,
        accessor: 'isEnabled',
        width: 144
      },
      {
        Header: translate('tableTypeColumn'),
        accessor: 'type',
        Cell: AttributeType,
        width: 144
      },
      {
        Header: translate('tableDescriptionColumn'),
        accessor: 'description',
        Cell: ({ row }) => (<Description row={row} isDefaultTemplate={device.isDefaultTemplate} />), /* eslint-disable-line */
        width: 78,
        disableSortBy: true
      },
      {
        Header: translate('tableDetailsColumn'),
        Cell: ({ row }) => (<Details row={row} isConfiguredDevice={device.phase !== 'draft'} />), /* eslint-disable-line */
        width: 80,
        disableSortBy: true
      }
    ], [JSON.stringify(device), selectedType] // eslint-disable-line
  )

  const onSearch = (value) => {
    setSearchQuery(value)
  }

  const emptyList = {
    filteredMessage: translate('noResults'),
    unfilteredMessage: translate('emptyListMessage')
  }

  const results = useSearch(filteredResults, searchQuery, searchFields, true)
  return (
    <>
      <FilterWrapper>
        <Select name={translateAttributes('filteringTypeLabel')} options={typeOptions} onChange={item => setSelectedType(item.value)} data-testid="device-status-filter" optionsTestIDRoot="device-status" />
        <Search searchFields={searchFields} onSearch={onSearch} placeholderText={translate('filterByName')} />
      </FilterWrapper>
      <Table
        isLoading={isLoading}
        columns={columns}
        data={results}
        isFiltered={isFiltered}
        emptyList={emptyList}
        initialSortBy="name"
      />
    </>
  )
}

DeviceAttributesTable.propTypes = {
  device: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired
}

export default DeviceAttributesTable
