import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Switch, Redirect, useParams, useHistory
} from 'react-router-dom'

import Icon from '@mdi/react'
import {
  mdiHexagonMultipleOutline, mdiDomain, mdiAccountMultipleOutline, mdiFileDocumentBoxOutline
} from '@mdi/js'

import useLocalStorage from '../../../Utils/Hooks/useLocalStorage'
import useTranslate from '../../../Utils/Hooks/useTranslate'
import useFeatureFlags from '../../../Utils/Hooks/useFeatureFlags'
import useErrorRouter from '../../../Utils/Hooks/useErrorRouter'
import useAsyncCallback from '../../../Utils/Hooks/useAsyncCallback'

import UserContext from '../../../State/User/Context'
import NotificationsContext from '../../../State/Notifications/Context'
import OrgContext from '../../../State/Organization/Context'
import MyAccount from '../MyAccount'
import OrganizationSettings from '../OrganizationSettings'
import Organizations from '../Organizations'
import Jobs from '../Jobs'
import Devices from '../Devices'
import DeviceAttributes from '../DeviceAttributes'
import Templates from '../Templates'
import Attributes from '../Attributes'
import Team from '../Team'
import NotFound from '../NotFound'
import NavLink from '../../NavLink'
import OrganizationSelector from '../../OrganizationSelector'
import UserDropdown from '../../UserDropdown'
import Spinner from '../../Spinner'
import Logo from '../../Logo'
import SupportLink from '../../SupportLink'
import StatefulRoute from './Components/StatefulRoute'

import {
  PageContainer, SideNavigation, LogoContainer, BodyContainer, NavMenu, NavMenuItem, ButtonContainer
} from './Styles'

const Dashboard = ({ match }) => {
  const [supportPath, setSupportPath] = useState('')
  const [orgSelectPath, setOrgSelectPath] = useState('')
  const {
    actions: { setReferrer, resetSuccessInvite },
    selectors: {
      isLoading, isInitialized, getReferrer, getOrganizations, getUser, getUID, isFromSuccessInvite, getInviteOrgId
    }
  } = useContext(UserContext)
  const { actions: { addNotification } } = useContext(NotificationsContext)
  const { actions: { fetchOrganizationInfo } } = useContext(OrgContext)
  const { organizationId } = useParams()
  const [execute, , inviteOrgIsLoading, organization] = useAsyncCallback(fetchOrganizationInfo)
  const { name: organizationName } = organization || {}
  const [defaultOrg, setDefaultOrg] = useLocalStorage(getUID(), organizationId || null)
  const adminOrgs = getOrganizations().filter(permission => permission.role === 'admin')
  const hasAccess = getOrganizations().find(org => org.id === organizationId && org.status !== 'inactive')
  const history = useHistory()
  const translate = useTranslate('globalNavigation')
  const translateInvite = useTranslate('invites')
  const { getFeatureFlag } = useFeatureFlags()
  const areTemplatesEnabled = getFeatureFlag('templatesEnabled')
  const showSupportLink = getFeatureFlag('displaySupportLink')
  useErrorRouter(getOrganizations(), 'id', organizationId, isLoading())

  const internalPaths = [
    {
      id: 'jobs',
      name: translate('jobs'),
      IconComponent: () => <Icon path={mdiDomain} size={1} />
    },
    ...areTemplatesEnabled ? [{
      id: 'templates',
      name: translate('templates'),
      IconComponent: () => <Icon path={mdiFileDocumentBoxOutline} size={1} />
    }] : [],
    {
      id: 'team',
      name: translate('users'),
      IconComponent: () => <Icon path={mdiAccountMultipleOutline} size={1} />
    }
  ]

  useEffect(() => {
    if (getReferrer() && getReferrer().pathname.startsWith('/my-account')) {
      history.push(getReferrer().pathname)
      setReferrer(null)
    }
  }, []) // eslint-disable-line

  // Set the default org in local storage when the dashboard loads
  useEffect(() => {
    const orgPermissions = getOrganizations()

    if (orgPermissions.every(permission => permission.status === 'inactive' || permission.status === 'archived') || (organizationId && !hasAccess)) {
      history.push('/error', { isPermissionError: true })
    }

    if (orgPermissions.every(permission => permission.role === 'installer') && !isLoading()) {
      history.push('/download')
    }
    if (organizationId && hasAccess) {
      setDefaultOrg(organizationId)
    } else if (!defaultOrg) {
      setDefaultOrg(adminOrgs[0] && adminOrgs[0].id)
    }
  }, [organizationId]) // eslint-disable-line

  useEffect(() => {
    const retrieveOrgName = async (orgId) => {
      await execute(orgId)
    }
    if (isFromSuccessInvite()) {
      retrieveOrgName(getInviteOrgId())
    }
  }, []) // eslint-disable-line

  useEffect(() => {
    if (!inviteOrgIsLoading && organizationName) {
      addNotification(`${translateInvite('successInviteNotification')} ${organizationName}`, 'success', 3)
      resetSuccessInvite()
    }
  }, [inviteOrgIsLoading]) // eslint-disable-line

  if (isLoading() && !isInitialized()) {
    return <Spinner message={translate('loading')} />
  }

  return (
    <PageContainer>
      <SideNavigation ariaLabel="Main Navigation" data-testid="main-nav">
        <OrganizationSelector />
        <LogoContainer>
          <Logo size={170} />
        </LogoContainer>
        <NavMenu>
          {getUser().admin
            && (
              <NavMenuItem key="organizations" data-testid="main-nav-item">
                <NavLink
                  to={`/organization/${organizationId || defaultOrg}/organizations`}
                  activeClassName="active"
                  data-testid={`main-nav-${translate('organizations').toLowerCase()}`}
                >
                  <Icon path={mdiHexagonMultipleOutline} size={1} />{translate('organizations')}
                </NavLink>
              </NavMenuItem>
            )
          }
          {
            internalPaths.map(({ id, name, IconComponent }) => (
              <NavMenuItem key={id} data-testid="main-nav-item">
                <NavLink
                  to={`/organization/${organizationId || defaultOrg}/${id}`}
                  activeClassName="active"
                  data-testid={`main-nav-${name.toLowerCase()}`}
                >
                  <IconComponent />{name}
                </NavLink>
              </NavMenuItem>
            ))
          }
        </NavMenu>
      </SideNavigation>
      <BodyContainer>
        <ButtonContainer>
          {showSupportLink && (<SupportLink endpoint={supportPath} />)}
          <UserDropdown />
        </ButtonContainer>
        <Switch>
          <Redirect exact from="/organization/:organizationId" to={`${match.url}/${orgSelectPath || 'jobs'}`} />
          <StatefulRoute setState={setSupportPath} setOrgSelectState={setOrgSelectPath} path={`${match.path}/jobs`} exact component={Jobs} />
          <StatefulRoute setState={setSupportPath} setOrgSelectState={setOrgSelectPath} path={`${match.path}/jobs/:jobsiteId`} exact component={Devices} />
          <StatefulRoute setState={setSupportPath} setOrgSelectState={setOrgSelectPath} path={`${match.path}/jobs/:jobsiteId/devices/:deviceId`} component={DeviceAttributes} />
          <StatefulRoute setState={setSupportPath} setOrgSelectState={setOrgSelectPath} path={`${match.path}/templates/:templateId`} component={Attributes} />
          <StatefulRoute setState={setSupportPath} setOrgSelectState={setOrgSelectPath} path={`${match.path}/templates`} component={Templates} />
          <StatefulRoute setState={setSupportPath} setOrgSelectState={setOrgSelectPath} path={`${match.path}/team`} component={Team} />
          <StatefulRoute setState={setSupportPath} setOrgSelectState={setOrgSelectPath} path={`${match.path}/organizations`} component={Organizations} />
          <StatefulRoute setState={setSupportPath} setOrgSelectState={setOrgSelectPath} path={`${match.path}/organization-settings`} component={OrganizationSettings} />
          <StatefulRoute setState={setSupportPath} path="/my-account" component={MyAccount} />
          <StatefulRoute setState={setSupportPath} component={NotFound} />
        </Switch>
      </BodyContainer>
    </PageContainer>
  )
}

Dashboard.propTypes = {
  match: PropTypes.object.isRequired
}

export default Dashboard
