import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import Icon from '@mdi/react'
import { mdiPencilOutline, mdiFileDocumentBoxOutline } from '@mdi/js'

import PanelContext from '../../../../State/Panel/Context'
import TemplateContext from '../../../../State/Templates/Context'

const Details = ({ row }) => {
  const { organizationId, templateId } = row.original
  const rowIndex = row.index + 1
  const isDefaultTemplate = organizationId === 'default'
  const { actions: { changeView } } = useContext(PanelContext)
  const { actions: { setSelectedTemplateId } } = useContext(TemplateContext)

  const handleClick = () => {
    const panelView = isDefaultTemplate ? 'templates/view' : 'templates/edit'
    setSelectedTemplateId(templateId)
    changeView(panelView)
  }

  return (
    <button
      type="button"
      title={isDefaultTemplate ? 'Read Template' : 'Edit Template'}
      data-testid={`templates-${isDefaultTemplate ? 'read' : 'edit'}-button-${rowIndex}`}
      onClick={handleClick}
    >
      <Icon path={isDefaultTemplate ? mdiFileDocumentBoxOutline : mdiPencilOutline} size={1} />
    </button>
  )
}

Details.propTypes = {
  row: PropTypes.object.isRequired
}

export default Details
