import React from 'react'
import PropTypes from 'prop-types'

import useTranslate from '../../../../Utils/Hooks/useTranslate'

import { TableCell } from '../../../Table'
import formatTime from '../../../../Utils/formatTime'

const LastModified = ({ row }) => {
  const { updatedAt } = row.original
  const translate = useTranslate('devicesPage')
  const translateTable = useTranslate('tableFiltering')

  return (
    <TableCell>
      {updatedAt
        ? formatTime(updatedAt, translateTable('dateFormattingToday'), translateTable('dateFormattingYesterday'))
        : <i>{translate('cellFormatting.undefined')}</i>
      }
    </TableCell>
  )
}

LastModified.propTypes = {
  row: PropTypes.object.isRequired
}

export default LastModified
