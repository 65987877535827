import React from 'react'
import PropTypes from 'prop-types'

import { TableCell } from '../../../Table'

const Description = ({ row }) => {
  const { description } = row.original

  return <TableCell data-testid="templates-button-group">{description}</TableCell>
}

Description.propTypes = {
  row: PropTypes.object.isRequired
}

export default Description
