import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { TableCell } from '../../../Table'
import NotificationsContext from '../../../../State/Notifications/Context'
import useTranslate from '../../../../Utils/Hooks/useTranslate'

/* eslint-disable */
const OrganizationName = ({ row, setSelectedOrg }) => {
  const { original, index } = row
  const { actions: { addNotification } } = useContext(NotificationsContext)
  const history = useHistory()
  const translate = useTranslate('organizationsPage')
  const handleClick = () => {
    setSelectedOrg(original.id)
    history.push(`/organization/${original.id}/jobs`)
    addNotification(`${translate('orgChangedNotification')} ${original.name}`, 'success', 2)
  }

  return (
    <TableCell>
      <div style={{ textDecoration: 'underline' }} onClick={handleClick} data-testid={`organization-name-link-${index + 1}`}>
        {original.name}
      </div>
    </TableCell>
  )
}
OrganizationName.propTypes = {
  row: PropTypes.object.isRequired,
  setSelectedOrg: PropTypes.func.isRequired
}
export default OrganizationName
